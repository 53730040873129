import React from "react";
import "./Loading.css";
import { DefaultLogo } from "../../data/images";

function Loading() {
	return (
		<div className='loading'>
			<div className='logo'>
				<img src={DefaultLogo} alt='' />
			</div>
		</div>
	);
}

export default Loading;
