import React from "react";
import { useLocation } from "react-router-dom";

import "./LogoHeader.css";
import { ArrowBack } from "../../../data/Icons";
import { DefaultLogo } from "../../../data/images";

const LogoHeader = () => {
	const location = useLocation();

	const handleGoBack = () => {
		window.history.back();
	};

	const NavigateToHomePage = () => {
		window.open("http://modelss.co", "_blank");
	};
	return (
		<>
			<div className='logo-header'>
				{location.pathname === "/RestorePassword" ||
				location.pathname === "/SendVerificationCode" ||
				location.pathname === "/VerificationPage" ||
				location.pathname === "/LogInVerificationCode" ||
				location.pathname === "/CreateNewPassword" ? (
					<div
						style={{ cursor: "pointer" }}
						className='box-logo d-flex justify-content-start align-items-center'
						onClick={handleGoBack}>
						<ArrowBack />
						<span
							style={{
								color: "#1948a0",
								fontSize: "18px",
								fontWeight: "400",
								paddingRight: "10px",
							}}>
							العودة للخلف
						</span>
					</div>
				) : (
					<div
						className='box-logo'
						onClick={NavigateToHomePage}
						style={{ border: " 1px solid #f75e22" }}>
						<img src={DefaultLogo} alt='' />
					</div>
				)}
			</div>
		</>
	);
};

export default LogoHeader;
